<template>
  <v-app-bar
    class="map-bar"
    app
    dense
    :height="48"
  >
    <v-toolbar-title
      class="py-1"
      style="height: 100%;"
    >
      <v-btn
        text
        tile
        x-large
        exact
        color="primary"
        height="100%"
        :disabled="isPloting || hasSlicing"
        @click="backToMap"
      >
        <v-icon left>
          mdi-undo-variant
        </v-icon>
        {{ $t('back_to_map') }}
      </v-btn>
    </v-toolbar-title>

    <v-subheader class="text-subtitle-1">
      {{ $t('post_processing_model') }}
    </v-subheader>

    <SaveDialog
      v-on="$listeners"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :disabled="!canSave"
          text
          tile
          x-large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-file-plus-outline
          </v-icon>
          {{ $t('save_as_new_file') }}
        </v-btn>
      </template>
    </SaveDialog>

    <v-spacer />

    <TranslationMenu>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          text
          tile
          large
          height="100%"
          v-on="on"
        >
          <v-icon>
            mdi-translate
          </v-icon>
        </v-btn>
      </template>
    </TranslationMenu>

    <UserAvatar v-if="userName">
      <template #activator="{ on, attrs }">
        <v-btn
          class="bg-ease"
          v-bind="attrs"
          dark
          text
          tile
          large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-account-circle
          </v-icon>
          {{ userName }}
        </v-btn>
      </template>
    </UserAvatar>
  </v-app-bar>
</template>

<script>
import SaveDialog from '@/components/Map/SaveDialog'
import UserAvatar from '@/components/UserAvatar'
import TranslationMenu from '@/components/TranslationMenu'

import { mapState, mapGetters } from 'vuex'

import store from '@/store'

export default {
  name: 'MapBar',

  components: {
    SaveDialog,
    UserAvatar,
    TranslationMenu
  },

  inject: {
    mapNavHeight: {
      from: 'mapNavHeight',
      default: 48
    }
  },

  beforeRouteEnter(to, from, next) {
    // 要有圖層才能進到後處理頁面
    if (!store.getters['map/layerNodes'].length) {
      if (from.name) {
        return next(false)
      }

      return next({
        name: 'Map',
        params: to.params
      })
    }

    next()
  },

  computed: {
    ...mapState({
      isPloting: state => state.postprocess.plot.isPloting,
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      userName: 'user/userName',
      layerNodes: 'map/layerNodes',
      isPostprocessModelByRoute: 'map/isPostprocessModelByRoute'
    }),

    isPostprocessModel() {
      return this.isPostprocessModelByRoute(this.$route)
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    canSave() {
      return this.isPostprocessModel &&
      this.currentLayerNode?.stagingFile
    },
    canPlot() {
      return this.isPostprocessModel && this.getRoleByResourceId(
        this.project, this.currentLayerNode?.parentVtk
      )?.read
    },
    layerNodesWithStagingFile() {
      return this.layerNodes.filter(layerNode => !!layerNode.stagingFile)
    },
    hasStagingLayerNode() {
      return this.layerNodesWithStagingFile.length
    },
    hasSlicing() {
      return this.layerNodes.some(layerNode => layerNode.isSlicing)
    }
  },

  created() {
    if (!this.userName) {
      this.fetchUser()
    }

    this.$store.dispatch('postprocess/init')
  },

  methods: {
    fetchUser() {
      this.$store.dispatch('user/fetchUser')
    },
    backToMap() {
      if (
        this.hasStagingLayerNode &&
        !window.confirm('發現尚未存檔的圖層，是否確定不存檔離開?')
      ) {
        return
      }

      if (this.hasStagingLayerNode) {
        const mapset = this.currentLayerNode.parent
        this.layerNodesWithStagingFile.forEach(layerNode => {
          layerNode.setStagingFile(null)
          layerNode.toggleMeshVisible(layerNode.sourceMesh, true)
          const beforeNode = mapset.children[layerNode.index + 1]
          this.$store.dispatch('map/addLayer', {
            fileNode: layerNode,
            beforeNode,
            fitBounds: false
          })
        })
      }

      this.$router.push({
        name: 'Map',
        params: this.$route.params,
        query: this.$route.query
      })
    }

  }

}
</script>

<style lang="scss" scoped>
.map-bar.v-app-bar.v-toolbar.v-sheet {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.79);

  ::v-deep .v-toolbar__content {
    padding-right: 0;
  }
}
</style>
